<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" />
        <div class="clearfix"></div>
      </div>
      <div class="h-8vh h-md-0"></div>
      <section class="landing_view h-md-100vh">
        <div class="row d-flex align-items-start justify-content-end">
          <div class="col-12 col-md-10">
            <div class="row">
              <div class="col-12 mt-5">
                <h1 class="large-text fnt-tny text-uppercase text-center">
                  {{ $t('mom shop - ai powered digital store creator') }}
                </h1>
                <h2 class="large-text-2 text-capitalize hide_for_mobile">
                  {{ $t('Boost Your Online Presence: Get Your Business Digital with MOM Shop App.') }}
                </h2>
              </div>
              <div class="col-12">
                <p>
                  {{ $t('AI-powered Simple, flexible, and powerful Order management system with integrated Cloud POS, HRMS, and Delivery Management Software.') }}
                </p>
              </div>
              <div class="col-12">
                <strong>
                  <a
                    class="custom-design custom-design-black"
                    href="/shop/login"
                  >
                    {{ $t('GO ONLINE') }}
                  </a>
                </strong>
              </div>
              <div class="col-12">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="require('@/assets/images/landing/google-play-store.png')" class="gplay_img" fluid />
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-center text-uppercase text-light">
            Your All-in-One Solution for Seamless Shopping and Business Growth.
          </h1>
          <h2 class="large-text-2">MOM Shop App is your gateway to expanding your retail business in the digital age. We offer a lifetime access opportunity to empower shop owners with the tools and features needed for success in the world of e-commerce.</h2>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row">
            <div class="col-12 col-md-4 mb-1 mb-md-0">
              <div class="row d-flex justify-content-center">
                <div class="col-10">
                  <b-img :src="require('@/assets/images/landing/Website/MOM Shop.webp')" class="home_img" fluid />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div>
                <p>Instant Visibility: Join our platform and instantly showcase your products to a vast online audience. With MOM Shop App, your store gains immediate visibility, ensuring that potential customers can discover your offerings effortlessly.</p>
                <p>Effortless Shopping: Simplify the shopping experience for your customers with our intuitive interface. Thanks to our user-friendly design, shoppers can browse and purchase your products in just a few clicks.</p>
                <p>Local & Global Reach: Expand your customer base beyond your local area. MOM Shop App allows you to reach local customers and expand your business globally. Connect with potential clients from around the world and tap into new markets.</p>
                <p>Secure Payments: Concerned about payment security? Rest assured, the MOM Shop App ensures secure and hassle-free transactions for both you and your customers. Funds are directly deposited into your company account, providing peace of mind.</p>
                <p>Data-Driven Growth: Harness the power of data to drive your business forward. MOM Shop App provides powerful analytics tools that allow you to make informed decisions. Track performance, analyze trends, and refine your product offerings for maximum profitability.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Create an online profile in just 60 seconds, in 3 easy steps') }}
          </h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row landing_items_view">
            <div class="col-12 col-md-4 landing_item" v-for="(item, ik) in landingItems" :key="`ik_${ik}`">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 col-md-8 mx-auto">
                      <b-img
                        fluid
                        :src="require(`@/assets/images/landing/shop/${item.img}`)"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-px-5">
                  <span
                    class="custom-design"
                  >
                    {{ item.title }}
                  </span>
                  <span v-for="(itemp, ikp) in item.text" :key="`itp_${ikp}_${ik}`">
                    <p class="text-muted" v-html="itemp"></p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Solution for Every Business') }}
          </h1>
        </div>
      </div>
      <div class="row landing_grids">
        <div class="col-12 col-md-4 grid_items" v-for="(menu, index) in servicesList" :key="`l_card_${index}`">
          <div class="ai_widget_img">
            <b-img fluid :src="require(`@/assets/images/landing-shop/${menu.image}`)" class="home_img" />
          </div>
          <div class="ai_widget_label custom-design mx-auto">
            <span>{{ $t(menu.title) }}</span>
          </div>
        </div>
      </div>
    </section>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Why choose MOM to create your online store?') }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-10 mx-auto text-center">
          <ol class="why_mom_listing" start="1">
            <li>
              0% Commission: Say goodbye to hefty commission fees. With MOM Shop, you keep 100% of your hard-earned profits. We believe in supporting your growth without cutting into your earnings.
            </li>
            <li>
              Analytics & Reports: Gain valuable insights into your business's performance with our robust analytics and reporting tools. Track key metrics, understand customer behavior, and make data-driven decisions to optimize your operations and increase profitability.
            </li>
            <li>
              Delivery Management Software: Seamlessly handle your order deliveries with our built-in delivery management system (DMS). Efficiently dispatch orders, track delivery progress, and provide your customers with a reliable delivery experience.
            </li>
            <li>
              Table Bookings: Expand your services beyond traditional e-commerce with table bookings. Whether you're in the restaurant or hospitality industry, MOM Shop supports table reservations to enhance your customer experience.
            </li>
            <li>
              Direct Payment to Bank: We understand the importance of quick and hassle-free payments. MOM Shop allows you to receive payments directly to your bank account, ensuring your cash flow is always smooth.
            </li>
            <li>
              Order Dashboard: Stay organized and in control with our intuitive order dashboard. Monitor incoming orders, manage customer requests, and streamline your order fulfillment process.
            </li>
            <li>
              Promotion and Loyalty Programs: Boost your sales and customer retention with attractive promotions and loyalty programs. MOM Shop makes it easy to create and manage these initiatives to keep your customers coming back for more.
            </li>
            <li>
              Cloud POS (Point of Sale): Simplify transactions with the Cloud POS system. Accept payments seamlessly, generate receipts, and manage finances efficiently from one platform or connect to your existing POS like Petpooja.
            </li>
            <li>
              Automated Invoicing: Say goodbye to manual invoicing tasks. MOM Shop automates the invoicing process, saving you time and reducing the risk of errors.
            </li>
            <li>
              Global Payment Support: Expand your business globally with confidence. MOM Shop supports a wide range of global payment methods like Stripe, PayPal, Phonepe, Razorpay, Flutterwave making it easy for customers from around the world to purchase from your store.
            </li>
            <li>
              HRMS : Onboard and manage a global workforce. Review employee performance Manage attendance, shifts, timesheets, and leave
            </li>
          </ol>
        </div>
      </div>
    </section>

    <section class="landing_section text-center">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center text-light">
            Pricing
          </h1>
          <p>
            Free forever, No credit card required
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-11 col-md-12 mx-auto">
          <SubscriptionList 
            :subscriptions="storeSubscriptions"
            @upgradeSubscription="upgradeSubscription"
            :upgradeBtn="`Subscribe`"
          />
        </div>
      </div>
    </section>

    <LandingPageFooter :trasparentFooter="true" />
    <AppFooterBlack :trasparentFooter="true" />
    
  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, dateTimeFormat } from '@/utilities'

export default {
  components: {
    LandingPageFooter,
    LandingPageHeader,
    AppFooterBlack,
    SubscriptionList
  },
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`;
      }
    }
  },
  computed: {
    ...mapGetters('subscriptionPlan', [
        'isSubscriptionLoaded',
        'storeSubscriptions',
        'isSubscriptionCountryLoaded',
        'countries',
    ]),
    servicesList() {
      return [
        {
          title: 'Restaurants & Cloud Kitchen',
          image: 'restaurants-cloud-kitchen.webp',
          url: '/user/store/list/restaurant',
        },
        {
          title: 'Electronics',
          image: 'electronics.webp',
          url: '/user/store/list/grocery',
        },
        {
          title: 'Fashion & Accessories',
          image: 'fashion-accessories.webp',
          url: '/user/services/subcategories/list/salon',
        },
        {
          title: 'Groceries & Mini Marts',
          image: 'groceries-mini-marts.webp',
          url: '/user/store/list/market',
        },
        {
          title: 'Home Decor',
          image: 'home-decor.webp',
          url: '/user/store/list/gifts',
        },
        {
          title: 'Gifts & Stationery',
          image: 'gifts-stationery.webp',
          url: '/user/services/subcategories/list/repairs',
        },
      ]
    },
    landingItems: {
      get(){
        return [
          {
            img: 'step1.png',
            title: 'STEP 1',
            text: [
              `<strong class="text-light">
                ${this.$t('Register your store')}
              </strong>
              - ${this.$t('Enter your store name, logo, address & other details and get your custom store link ready in 60 seconds!')}`,
              `${this.$t('Psss.....We will let you in on a secret, your store will also be powered by an built in (DMS) delivery management software.')}`
            ]
          },
          {
            img: 'step2.png',
            title: 'STEP 2',
            text: [
              `<strong class="text-light">
                ${this.$t('Add your products ')}
              </strong>
              - ${this.$t('Create your category first and start adding your products, set prices, add color and sizes (if necessary) and allow your customers to check instantly.')}`
            ]
          },
          {
            img: 'step3.png',
            title: 'STEP 3',
            text: [
              `<strong class="text-light">
                ${this.$t('Start Selling ')}
              </strong>
              - ${this.$t('This is where it all begins. Your store is ready for the world. Share the link with all your friends & family via Email, WhatsApp or Facebook and start taking orders.')}`
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('subscriptionPlan', [
        'getSubscriptionPackages',
        'getCountries',
    ]),
    isEmpty, dateTimeFormat,
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
    upgradeSubscription(subscription){
      this.$router.push({
        name: 'apps-shop-registration'
      })
    }
  },
  async mounted() {
      if (!localStorage.getItem('shopSelectedCountryId')) {
        try {
          const _ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
          const ipInfo = await _ipInfo.json()
          if(!isEmpty(ipInfo?.location)){
            if(!this.isSubscriptionCountryLoaded){
              await this.getCountries().then(data => {
                if (data._statusCode === 200) {
                    const countries = data.responseData
                    const isCountryExist = countries.find(x => x.country_name == ipInfo.location.country.name)
                    if (isCountryExist) {
                      localStorage.setItem('shopSelectedCountryId', isCountryExist.id)
                    }
                }
              })
            }else{
              const countries = this.countries
              const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
              if (isCountryExist) {
                localStorage.setItem('shopSelectedCountryId', isCountryExist.id)
              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
      if (!this.isSubscriptionLoaded) {
          await this.getSubscriptionPackages()
      }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-color: #040612;
  background-image: url('~@/assets/images/landing/shop/main2.webp');
  padding-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
</style>
